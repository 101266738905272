/**
 * YouTubeのプレーヤーの設定を行う
 * @param ytArea プレーヤーを埋め込む場所指定
 * @param ytID 埋め込むYouTube ID指定
 */
export function setYTPlayer(ytArea, ytID) {
  // APIの読み込み
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  let ytPlayer;
  // プレーヤーの埋め込み
  window.onYouTubeIframeAPIReady = function () {
    ytPlayer = new YT.Player(ytArea, {
      videoId: ytID,
      playerVars: {
        rel: 0,
        controls: 0,
        showinfo: 0,
        modestbranding: 1,
        loop: 1,
        playsinline: 1,
        wmode: 'transparent',
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  };

  // YouTubeの準備完了後
  function onPlayerReady() {
    ytPlayer.playVideo();
    ytPlayer.mute();
  }

  // 再生完了後
  function onPlayerStateChange(e) {
    const ytStatus = e.target.getPlayerState();
    if (ytStatus === YT.PlayerState.ENDED) {
      ytPlayer.playVideo();
      ytPlayer.mute();
    }
  }
}

/**
 * 動画の画面比率を設定する
 */
export function setMovieScreenRetio() {
  // 上下左右に出てくる黒帯を非表示
  const WIN = $(window);
  const WIN_H = WIN.height();
  const WIN_W = WIN.width();

  const screen_switch = 0.5625;
  const screen_ratio = WIN_H / WIN_W;
  const ratio_H = WIN_H / screen_switch;
  const ratio_W = WIN_W * screen_switch;

  if (screen_ratio > screen_switch) {
    $('.js-movie').css({
      height: '120%',
      width: ratio_H,
      'margin-top': '0',
      'margin-left': -ratio_H / 2,
      left: '50%',
      top: '-11%',
    });
  } else {
    $('.js-movie').css({
      width: '100%',
      height: ratio_W,
      'margin-top': -ratio_W / 2,
      'margin-left': '0',
      top: '50%',
      left: '0',
    });
  }
}


export function mvCentering() {
  const widthDistance = 1080 - $(window).width();

  if ($(window).width() < 1080 && $(window).width() > 768) {
    $('.c-mv').css('marginLeft', -1 * widthDistance / 2);
  } else {
    $('.c-mv').removeAttr('style');
  }
}