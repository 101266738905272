/**
 * SP時のMVのモーダルの展開
 */
export function setMovieModal() {
  // ハンバーガーメニュー展開
  const $modalBtn = $('.js-modalBtn');
  const $movieModal = $('.js-movieModal');
  const $movieModalClose = $('.js-movieModalClose');

  $modalBtn.on('click', function () {
    $movieModal.addClass('is-view');
  });

  $movieModalClose.on('click', function () {
    $movieModal.removeClass('is-view');
    videoControl("pauseVideo");
  });

}

function videoControl(action) {
  var $playerWindow = $('#movieModal')[0].contentWindow;
  $playerWindow.postMessage('{"event":"command","func":"' + action + '","args":""}', '*');
}