export function setTag() {
  const $tag = $('.js-tag');
  const $tagAll = $('.js-tagAll');
  const $tagNews = $('.js-tagNews');
  const $tagTest = $('.js-tagTest');
  const $tagBlog = $('.js-tagBlog');
  const $tagPrincipal = $('.js-tagPrincipal');
  const $tagType = $('.js-tagType');

  $tag.on('click', function () {
    $tag.removeClass('is-active');
    $(this).addClass('is-active');
    const selectTag = $(this).data('tag');

    if (selectTag === 'all') {
      $tagType.removeClass('is-view');
      $tagType.addClass('is-hide');
      $tagAll.addClass('is-view');
    } else if (selectTag === 'news') {
      $tagType.removeClass('is-view');
      $tagType.addClass('is-hide');
      $tagNews.addClass('is-view');
    } else if (selectTag === 'test') {
      $tagType.removeClass('is-view');
      $tagType.addClass('is-hide');
      $tagTest.addClass('is-view');
    } else if (selectTag === 'blog') {
      $tagType.removeClass('is-view');
      $tagType.addClass('is-hide');
      $tagBlog.addClass('is-view');
    } else if (selectTag === 'principal') {
      $tagType.removeClass('is-view');
      $tagType.addClass('is-hide');
      $tagPrincipal.addClass('is-view');
    }
  });
}