import throttle from 'lodash/throttle';
import {
  setTag
} from './modules/tag';
import {
  setMovieModal
} from './modules/topModal';
import {
  setYTPlayer,
  setMovieScreenRetio
} from './modules/movie';
import {
  mvCentering
} from './modules/movie';
import {
  isSp
} from './modules/util';

$(() => {
  const VIDEO_ID = '4JlHZATrHvM';

  setTag();
  setYTPlayer('mv', VIDEO_ID);
  setMovieScreenRetio();
});

$(window).on('load', function () {
  if (!isSp()) {
    setMovieScreenRetio();
  }

  setTimeout(function () {
    $('.js-movie').addClass('is-view');
    setMovieModal();
  }, 900);
});

// resize
$(window).on(
  'resize',
  throttle(() => {
    if (!isSp()) {
      setMovieScreenRetio();
    }
  }, 10)
);

// resize&load
$(window).on(
  'resize load',
  throttle(() => {
    // TOPページの下部バナーのテキストエリアの高さを可変にする処理
    if (!isSp()) {
      const $bnrItem = $('.js-bnrSlide').find('.c-bnr_item');
      let bnrItemHeight = 0;

      $bnrItem.removeAttr('style');

      $bnrItem.each(function () {
        if (bnrItemHeight < $(this).outerHeight()) {
          bnrItemHeight = $(this).outerHeight();
        }
      });

      $bnrItem.css('height', bnrItemHeight);
    }

    const windowHeight = $(window).height();
    const headerHeight = $('.l-header').outerHeight();
    const asideHeight = $('.l-header_side').innerHeight();

    const spMvHeight = windowHeight - headerHeight - asideHeight + 1;

    if ($(window).width() < 769) {
      $('.c-mv').css('height', spMvHeight);
    } else {
      $('.c-mv').removeAttr('style');
    }

    if ($(window).width() < 1080 && $(window).width() > 768) {
      mvCentering();
    } else {
      $('.c-mv').removeAttr('style');
    }
  }, 10)
);